import React, { useMemo } from 'react';

interface IChip {
    testId?: string;
    color?: 'success' | 'primary' | 'danger' | 'default' | 'info' | 'severe';
    size?: 'small' | 'large';
}

export const Chip: React.FC<IChip> = (props) => {
    const { color, children, testId, size } = props;

    const bgColor = useMemo(() => {
        switch (color) {
            case 'primary':
                return 'bg-primary/10';
            case 'danger':
                return 'bg-red-200/50';
            case 'default':
                return 'bg-neutral-300/50';
            case 'success':
                return 'bg-green-200/50';
            case 'info':
                return 'bg-blue-200/50';
            case 'severe':
                return 'bg-red-700';
        }

        return '';
    }, [color]);

    const textColor = useMemo(() => {
        switch (color) {
            case 'primary':
                return 'text-primary';
            case 'danger':
                return 'text-red-700';
            case 'default':
                return 'text-neutral-500';
            case 'success':
                return 'text-green-600';
            case 'info':
                return 'text-blue-600';
            case 'severe':
                return 'text-white';
        }

        return '';
    }, [color]);

    const sizeStyles = useMemo(() => {
        return size === 'small' ? 'px-1 py-0 text-xs h-min' : 'px-2 py-1 text-xs';
    }, [size]);

    return (
        <div
            data-test-id={testId ?? undefined}
            className={`flex w-max flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded ${sizeStyles} ${bgColor} ${textColor}`}
        >
            {children}
        </div>
    );
};

Chip.defaultProps = {
    color: 'default',
};
