import { useEffect, useState } from 'react';

const useOnlineStatus = (): boolean => {
    // Initialize with true as a default value if running on server
    const [isOnline, setIsOnline] = useState<boolean>(
        typeof navigator !== 'undefined' ? navigator.onLine : true
    );

    useEffect(() => {
        // Only run this effect in a browser
        if (typeof window === 'undefined' || typeof navigator === 'undefined') {
            return;
        }

        const goOnline = () => setIsOnline(true);
        const goOffline = () => setIsOnline(false);

        // Add event listeners for 'online' and 'offline' events
        window.addEventListener('online', goOnline);
        window.addEventListener('offline', goOffline);

        return () => {
            // Clean up event listeners when the component unmounts
            window.removeEventListener('online', goOnline);
            window.removeEventListener('offline', goOffline);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    return isOnline;
};

export default useOnlineStatus;
