import { FC, useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userAtom } from './userAtom';
import { useRouter } from 'next/router';
import useOnlineStatus from '../../hooks/useOnlineStatus';
import { useWhoAmIdQuery } from '../../hooks/api/user/useWhoAmIdQuery';

export const AuthGuard: FC<{ access?: string }> = ({ children, access }) => {
    // useWhoAmI();

    useWhoAmIdQuery(false);

    const isOnline = useOnlineStatus();

    const { id, roles } = useRecoilValue(userAtom);

    const { push } = useRouter();

    const roleValid = useCallback(() => {
        // const lsUser = localStorage.getItem('user') as string;
        //
        // // If device is offline, and a user was previously logged skip for offline mode
        // if (!isOnline && lsUser && lsUser !== '') {
        //     // eslint-disable-next-line no-console
        //     console.log('AUTHGUARD: SKIP FOR OFFLINE MODE');
        //     return true;
        // }

        if (!id) {
            return null;
        }
        if (!access) {
            return true;
        }

        return roles?.some((role) => role.access.includes(access));
    }, [access, id, roles]);

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log('id', id);

        if (roleValid() === false) {
            // eslint-disable-next-line no-console
            console.log("You don't have access to this page");

            if (isOnline) {
                // eslint-disable-next-line no-console
                console.log('Pushing to logout');
                void push('/logout');
            } else {
                // eslint-disable-next-line no-console
                console.log('No redirect');
            }
        }
    }, [isOnline, push, roleValid, id]);

    return <>{roleValid() && children}</>;
};
