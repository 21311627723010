import { FC, FunctionComponent, useEffect, useMemo } from 'react';
import localforage from 'localforage';
import { useContentfulNotifications } from '../../hooks/useContentfulNotifications';
import {
    IContentfulNode,
    IContentfulNodeType,
    IContentfulNotificationDocument,
    IContentfulNotificationType,
} from '../../interfaces/contentful';
import { v4 } from 'uuid';
import { Modal } from '../atoms/Modal/Modal';
import dayjs from 'dayjs';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { whatsNewRecoil, whatsNewShow } from './recoil/WhatsNewState';
import { usePermissions } from '../../hooks/usePermissions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
    faBugSlash,
    faCalendar,
    faCartPlus,
    faTriangleExclamation,
} from '@fortawesome/pro-duotone-svg-icons';

const lfWhatsNew = localforage.createInstance({ version: 4, name: 'whatsNew' });

interface IDocumentCollection {
    [key: string]: IContentfulNotificationDocument[];
}

const ContentfulNode: FC<{ node: IContentfulNode }> = ({ node }) => {
    switch (node.nodeType) {
        case IContentfulNodeType.Heading1:
            return (
                <h1 className="text-xl ">
                    {node.content?.map((n) => (
                        <ContentfulNode key={v4()} node={n} />
                    ))}
                </h1>
            );
        case IContentfulNodeType.Heading2:
            return (
                <h2 className="text-lg ">
                    {node.content?.map((n) => (
                        <ContentfulNode key={v4()} node={n} />
                    ))}
                </h2>
            );
        case IContentfulNodeType.Text:
            return <span>{node.value}</span>;
        case IContentfulNodeType.ListItem:
            return (
                <li className="list-disc">
                    {node.content?.map((n) => (
                        <ContentfulNode key={v4()} node={n} />
                    ))}
                </li>
            );
        case IContentfulNodeType.Paragraph:
            return (
                <div>
                    {node.content?.map((n) => (
                        <ContentfulNode key={v4()} node={n} />
                    ))}
                </div>
            );
        case IContentfulNodeType.UnorderedList:
            return (
                <ul>
                    {node.content?.map((n) => (
                        <ContentfulNode key={v4()} node={n} />
                    ))}
                </ul>
            );
        case IContentfulNodeType.EmbeddedAssetBlock:
            return (
                <>
                    {node.data?.target.fields.file.url && (
                        <img
                            src={node.data?.target.fields.file.url}
                            alt={node.data?.target.fields.description}
                        />
                    )}
                </>
            );
        default:
            return <></>;
    }
};

const ContentfulDocument: FC<{ document: IContentfulNotificationDocument }> = ({ document }) => {
    return (
        <div className="flex w-full flex-col gap-2 p-2 md:gap-4">
            <div className="grid grid-cols-[32px,auto] items-start gap-2">
                <div className="flex h-8 w-full items-center justify-center rounded bg-primary p-2">
                    {document.type === IContentfulNotificationType.Add && (
                        <FontAwesomeIcon icon={faPlus} className="h-4 text-white" />
                    )}
                    {document.type === IContentfulNotificationType.Fix && (
                        <FontAwesomeIcon icon={faBugSlash} className="h-4 text-white" />
                    )}
                    {document.type === IContentfulNotificationType.Feature && (
                        <FontAwesomeIcon icon={faCartPlus} className="h-4 text-white" />
                    )}
                    {document.type === IContentfulNotificationType.Notice && (
                        <FontAwesomeIcon icon={faInfo} className="h-4 text-white" />
                    )}
                    {document.type === IContentfulNotificationType.Warning && (
                        <FontAwesomeIcon icon={faTriangleExclamation} className="h-4 text-white" />
                    )}
                    {document.type === IContentfulNotificationType.Remove && (
                        <FontAwesomeIcon icon={faTrash} className="h-4 text-white" />
                    )}
                </div>
                <div className="text-semibold text-xl text-neutral-600">{document.title}</div>
            </div>
            <div className="col-span-12 flex flex-col items-start gap-2">
                {document.content.map((node) => (
                    <ContentfulNode key={v4()} node={node} />
                ))}
            </div>
        </div>
    );
};

const WhatsNewModal: FunctionComponent = () => {
    const show = useRecoilValue(whatsNewShow);
    const setShow = useSetRecoilState(whatsNewRecoil);

    const contentfulNotifications = useContentfulNotifications();

    const { isAdmin, isPureSV, isPureEK } = usePermissions();

    const messageCollection = useMemo((): IDocumentCollection => {
        const messagesObject: IDocumentCollection = {};

        if (!contentfulNotifications.data) return {};

        let count = 0;

        Object.entries(contentfulNotifications.data).filter(([date, notificationBody]) => {
            const body = notificationBody as {
                date: string;
                documents: IContentfulNotificationDocument[];
            };

            // Set Object per date
            messagesObject[date] = [];

            body.documents.forEach((document) => {
                const isBeforeOneMonth = dayjs(date).isBefore(dayjs().subtract(1, 'month'));

                if (isBeforeOneMonth) return;

                if (isAdmin) {
                    if (messagesObject[date] !== undefined) messagesObject[date]?.push(document);
                    count++;
                    return;
                }
                if (document.isSV && isPureSV) {
                    if (messagesObject[date] !== undefined) messagesObject[date]?.push(document);
                    count++;
                    return;
                }
                if (document.isEK && isPureEK) {
                    if (messagesObject[date] !== undefined) messagesObject[date]?.push(document);
                    count++;
                    return;
                }
            });
        });

        if (count > 0) return messagesObject;

        return {};
    }, [contentfulNotifications.data, isAdmin, isPureEK, isPureSV]);

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log('messageCollection', messageCollection);

        if (messageCollection && Object.keys(messageCollection).length > 0) {
            const dates = Object.keys(messageCollection);

            if (dates.length) {
                void lfWhatsNew.getItem<string>('lastTime').then((lastTime) => {
                    if (lastTime) {
                        const newestDate = dayjs(dates[0] ? dates[0] : '');

                        // Is newer
                        if (dayjs(newestDate).isAfter(lastTime)) {
                            // eslint-disable-next-line no-console
                            console.log('Show new messages');
                            setShow(() => {
                                return { show: true };
                            });
                            void lfWhatsNew.setItem('lastTime', newestDate.toISOString());
                        }
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('Show messages initially');

                        // Always show
                        setShow(() => {
                            return { show: true };
                        });
                        void lfWhatsNew.setItem('lastTime', dates[0]);
                    }
                });
            }
        }
    }, [contentfulNotifications.data, messageCollection, setShow]);

    return (
        <>
            <Modal
                show={show && Object.keys(messageCollection).length > 0}
                title={'Was ist neu?'}
                afterClose={() =>
                    setShow(() => {
                        return { show: false };
                    })
                }
                closeable={true}
            >
                <div className="relative flex flex-col gap-10">
                    {Object.entries(messageCollection).map(([date, documents]) => {
                        const isBeforeOneMonth = dayjs(date).isBefore(dayjs().subtract(1, 'month'));

                        // Not show if older than 1 month
                        if (isBeforeOneMonth) return undefined;

                        return (
                            <div key={v4()} className="relative flex flex-col gap-4">
                                <div className="relative flex flex-row items-center gap-2 bg-gradient-to-r from-primary via-primary to-primary-light p-2">
                                    <FontAwesomeIcon icon={faCalendar} className="h-6 text-white" />
                                    <span className="text-semibold text-lg text-white">
                                        {dayjs(date).format('DD.MM.YYYY')}
                                    </span>
                                </div>

                                <div className="flex flex-col gap-2">
                                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access */}
                                    {documents.map((document) => (
                                        <ContentfulDocument
                                            key={v4()}
                                            document={document as never}
                                        />
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Modal>
        </>
    );
};

export default WhatsNewModal;
