import { useQuery, UseQueryResult, useQueryClient } from 'react-query';
import { IUser } from '../../../interfaces/user/user.model';
import dayjs from 'dayjs';
import { useApi } from '../useApi';
import { contractKeys } from '../../contracts/queryKeyFactory';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userAtom } from '../../../components/Auth/userAtom';
import { useEffect } from 'react';

export const useWhoAmIdQuery = (forceApi: boolean): UseQueryResult<IUser | false, unknown> => {
    const { get } = useApi();
    const setUser = useSetRecoilState(userAtom);
    const user = useRecoilValue(userAtom);
    const queryClient = useQueryClient();

    const query = useQuery<IUser | false, unknown>(
        contractKeys.whoAmI,
        async (): Promise<IUser | false> => {
            const userResponse: IUser | false = await get<IUser>(
                `/auth/whoAmI?t=${dayjs().format('hhmmSSS')}`
            );
            if (userResponse) {
                setUser(userResponse);
            }
            return userResponse;
        },
        {
            // Enable or disable the query based on the forceApi flag
            enabled: !forceApi,
        }
    );

    useEffect(() => {
        if (forceApi && user.id === '') {
            // Force refetch from the API
            void query.refetch();
        } else if (!user || user.id === '') {
            // Attempt to get cached data when forceApi is false and user is not set
            const cachedData: IUser | false | undefined = queryClient.getQueryData<IUser | false>(
                contractKeys.whoAmI
            );

            if (cachedData !== undefined) {
                setUser(cachedData as IUser);
            }
        }
    }, [forceApi, query, queryClient, setUser, user]);

    return query;
};
