export interface IContentfulData {
    target: {
        metadata: {
            tags: string[];
        };
        sys: {
            space: {
                sys: {
                    type: 'Link';
                    linkType: 'Space';
                    id: string;
                };
            };
            id: string;
            type: 'Asset';
            createdAt: string;
            updatedAt: string;
            environment: {
                sys: {
                    id: 'master';
                    type: 'Link';
                    linkType: 'Environment';
                };
            };
            revision: number;
            locale: string;
        };
        fields: {
            title: string;
            description: string;
            file: {
                url: string;
                details: {
                    size: number;
                    image: {
                        width: number;
                        height: number;
                    };
                };
                fileName: string;
                contentType: string;
            };
        };
    };
}

export interface IContentfulNode {
    data?: IContentfulData;
    marks?: [];
    value?: string;
    nodeType: IContentfulNodeType;
    content?: IContentfulNode[];
}

export enum IContentfulNotificationType {
    Add = 'add',
    Notice = 'notice',
    Fix = 'fix',
    Feature = 'feature',
    Remove = 'remove',
    Warning = 'warning',
}

export interface IContentfulNotification {
    [key: string]: IContentfulNotificationBody;
}

export interface IContentfulNotificationBody {
    date: string;
    documents: IContentfulNotificationDocument[];
}

export interface IContentfulNotificationResponse {
    title: string;
    date: string;
    type: IContentfulNotificationType[];
    body: IContentfulNotificationBodyResponse;
    isSv: boolean;
    isEk: boolean;
}

export interface IContentfulNotificationDocument {
    title: string;
    data: IContentfulData;
    content: IContentfulNode[];
    nodeType: IContentfulNodeType.Document;
    type: IContentfulNotificationType;
    isSV: boolean;
    isEK: boolean;
}

interface IContentfulNotificationBodyResponse {
    data: IContentfulData;
    content: IContentfulNode[];
    nodeType: IContentfulNodeType.Document;
    type: IContentfulNotificationType;
}

export enum IContentfulNodeType {
    Heading1 = 'heading-1',
    Heading2 = 'heading-2',
    Document = 'document',
    Paragraph = 'paragraph',
    ListItem = 'list-item',
    Text = 'text',
    UnorderedList = 'unordered-list',
    EmbeddedAssetBlock = 'embedded-asset-block',
}
