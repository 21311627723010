import {
    IContentfulNodeType,
    IContentfulNotification,
    IContentfulNotificationBody,
    IContentfulNotificationDocument,
    IContentfulNotificationResponse,
    IContentfulNotificationType,
} from '../interfaces/contentful';
import { useNetworkState } from 'react-use';
import { useQuery } from 'react-query';
import { useApi } from './api/useApi';

export const useContentfulNotifications = () => {
    const { get } = useApi();

    const { online } = useNetworkState();

    return useQuery(
        ['notifications'],
        async () => {
            return get<IContentfulNotificationResponse[]>('/api/contentful/notification').then(
                (response) => {
                    const parsedNotifications: IContentfulNotification = {};

                    for (const notification of response as IContentfulNotificationResponse[]) {
                        let documents: IContentfulNotificationDocument[] = [];

                        if (notification.date in parsedNotifications) {
                            const soFar = parsedNotifications[
                                notification.date
                            ] as IContentfulNotificationBody;
                            documents = [...soFar.documents];
                        }

                        documents.push({
                            title: notification.title,
                            nodeType: IContentfulNodeType.Document,
                            type: notification.type[0] ?? IContentfulNotificationType.Add,
                            data: notification.body.data,
                            content: notification.body.content,
                            isSV: notification.isSv,
                            isEK: notification.isEk,
                        });

                        parsedNotifications[notification.date] = {
                            date: notification.date,
                            documents,
                        };
                    }

                    return parsedNotifications;
                }
            );
        },
        { enabled: online }
    );
};
